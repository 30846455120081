import React from 'react';
import classNames from 'classnames';

import style from './Section.module.scss';

interface Data {
	heading?: JSX.Element;
	heading2?: JSX.Element;
	text: JSX.Element;
	image?: {
		src: string;
		alt: string;
	};
}

interface Props {
	isFlexLayout: boolean;
	data: Data
}

const Section = ({data, isFlexLayout}: Props) => {
	const { heading, heading2, text, image } = data;
	return (
		<div className={style.container}>
			<div className={classNames(isFlexLayout ? style.flexRow : style.section)}>
				<div className={style.col6}>
					{ heading && <h1 className={style.title}>{heading}</h1>}
					{ heading2 && <h2 className={style.title}>{heading2}</h2>}
					<p className={style.paragraph}>{text}</p>
				</div>
				{image && <img src={image.src} alt={image.alt} className={style.image}/>}
			</div>
		</div>
	);
};

export default Section;
