import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { parsePhoneNumberFromString } from 'libphonenumber-js'

import style from './Contacts.module.scss';

interface Detail {
    company: string;
    address: string;
    city: string;
    country: string;
    email: string;
    phone: string;
}

interface ListItem {
    icon: string;
    country: string;
    contactDetails: Detail[];
}

const Contacts = () => {
    const list: ListItem[] = [
        {
            icon: 'ltFlag',
            country: 'Lithuania',
            contactDetails: [
                { company: 'UAB Spectro Finance' },
                { address: 'Gediminas av. 44A' },
                { city: 'Vilnius, LT-01110' },
                { country: 'Lithuania' },
                { email: 'info@spectrofinance.lt' },
                { phone: '+370 700 44940' }
            ]
        },
        {
            icon: 'eeFlag',
            country: 'Estonia',
            contactDetails: [
                { company: 'Spectro Finance OÜ' },
                { address: 'Narva mnt 7b-509' },
                { city: 'Tallinn 10117' },
                { country: 'Estonia' },
                { email: 'info@spectrofinance.ee' },
                { phone: '+372 683 8000' }
            ]
        }
    ];

    return (
        <div className={style.container}>
            <h2 className={style.heading2}>
                <FormattedMessage id="heading.getInTouch" defaultMessage="Get in touch"/>
            </h2>
            <div className={style.flexList}>
                { list.map((item: ListItem) => (
                    <div className={style.listItem} key={item.country}>
                        <span className={classNames(style.icon, style[item.icon])} />
                        <h3 className={style.heading4}>
                            {item.country}
                        </h3>
                        <ul>
                            {item.contactDetails.map((contact: Detail, index: number) => (
                                <li key={index}>
                                    {contact.company && <p className={style.paragraph}>{contact.company}</p>}
                                    {contact.address && <p className={style.paragraph}>{contact.address}</p>}
                                    {contact.city && <p className={style.paragraph}>{contact.city}</p>}
                                    {contact.country && <p className={style.paragraph}>{contact.country}</p>}
                                    {contact.email &&  <a className={style.paragraph} href={`mailto:${contact.email}`}>{contact.email}</a>}
                                    {contact.phone &&
                                        <a className={style.paragraph} href={parsePhoneNumberFromString(contact.phone).getURI()}>
                                            {parsePhoneNumberFromString(contact.phone).formatInternational()}
                                        </a>
                                    }
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    )
};

export default Contacts;