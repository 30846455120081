import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import Contacts from 'components/Contacts/Contacts';
import Seo from 'components/Seo/Seo';
import Section  from 'components/Section/Section';

import shield from 'assets/images/shield.png';
import 'assets/style/style.scss';

const translations = defineMessages({
	imageAlt: {
		id: 'imageAlt.section1',
		defaultMessage: 'Shield is above walking man.'
	},
	metaTitle: {
		id: 'metaTitle.main',
		defaultMessage: 'Spectro Finance'
	}
});

const IndexPage = () => {
	const { formatMessage } = useIntl();

	return (
		<>
			<Seo
				metaTitle={formatMessage(translations.metaTitle)}
				metaDescription={formatMessage(translations.metaTitle)}
			/>
			<Section
				data={{
					heading: <FormattedMessage id="heading.weAreSpectro" defaultMessage="We are Spectro Finance" />,
					text: <FormattedMessage id="text.section1" defaultMessage="Developing technology and services to ease online payment processing. Our goal as entrepreneurs is to offer our clients the best solutions to send and receive value over the internet, hence we have developed the most innovative methods, ranging from blockchain to instant payments. The word “Spectro” in our name refers to the spectrum of solutions we offer, showing freedom of choice." />,
					image: {
						src: shield,
						alt: formatMessage(translations.imageAlt)
					}
				}}
				isFlexLayout
			/>
			<Contacts />
		</>
	);
};

export default IndexPage;
